@import "../../../../styles/variables";

.chooseFileUpload {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin: 1rem 0;
}

.fileUploadDiv {
  display: flex;
  flex-flow: column;
  justify-content: center;
  width: 100%;

  .errorType {
    color: red;
    font-weight: bold;
    text-align: center;
  }

  .fileUpload {
    display: flex;
    flex-flow: row nowrap;

    width: 80%;
    margin: 20px auto;

    border: 1px solid $border_color_blue;
    border-radius: 5px;

    .chooseFile {
      padding: 8px 0;

      flex: 0 0 30%;
      min-width: 200px;
      background-color: #e0e0e0;
      text-align: center;

      cursor: pointer;

      &:hover {
        background-color: #a4a4a4;
      }
    }

    .divFile {
      flex-grow: 1;
      padding: 8px 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
    }
  }

  strong {
    text-align: center;
  }
}

.fileList {
  display: flex;
  flex-flow: column;
  height: 45vh;
  overflow-y: auto;

  .fileElem {
    display: flex;
    align-items: center;
    margin: .8rem;

    span {
      word-break: break-word;

      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
  }
}

.imagePreview {
  margin: auto;
  max-width: 100%;
  max-height: 20vh;
}

.imagePreview:hover {
  cursor: pointer;
}

.customInput {
  width: 50%;
  margin: 1rem;
  padding: 5px 10px;
  border: 2px solid $border_color_blue;
  border-radius: 16px;
}

.descDiv {
  display: inline-block;
  margin: auto 2rem;
  width: 60%;
  min-height: 42px;
  overflow-y: auto;
  border: $border_color_blue solid 1px;
  border-radius: 8px;
  padding: 0.5rem;
  color: black;
}

.dark {
  background-color: $background_input_text_dark;
  color: white;
}

.dark input {
  background-color: $background_input_text_dark;
  color: white;
}

.dark input:focus {
  background-color: $background_textarea_dark;
  color: white;
}

.dark label {
  color: white;
}

.btnLoadNewFile {
  margin-top: 1rem;
  margin-left: 1rem;
}

.description {
  margin: 1rem;
}