@import "src/styles/variables";

.divAccount {
  max-width: 120px;
  min-width: 50px;
  padding: 5px 5px;
  color: rgba(255, 255, 255, 0.8);
  z-index: 100;
  background-color: rgba(13, 110, 253, 0.2);
  border-radius: 5px;
  user-select: none;
  cursor: pointer;
  display: flex;
  flex-flow: row;
  align-items: center;

  span {
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }

  &:hover {
    background-color: rgba(13, 110, 253, 0.3);
  }
}

.accountDetails {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-flow: column;
  padding: 15px 20px;
  background-color: $white;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  border: 1px solid #507ebd;
  border-top: none;

  & > div {
    margin-top: 15px;
    width: 100%;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
  }

  select {
    margin-left: 10px;
  }

  .email {
    display: block;
    text-align: center;
  }

  .langSelect select {
    width: 100px;
  }

  .btnExit {
    width: 100%;
    max-width: 250px;
    margin: 0 auto;
  }
}