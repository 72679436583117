@import "src/styles/variables";

.header {
  width: 100%;
  height: 6vh;
  font-size: $sub_title_text;
  background-color: $white;
  padding: 0 1em;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  user-select: none;

  .companyName {
    strong {
      opacity: 0.8;
      color: $blue;
    }
  }

  .navBarBlock{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }
}