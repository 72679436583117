.header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 7vh;
  background: linear-gradient(to right, #293f69, #507ebd);
  padding: 0 1em;

  .logoDiv {
    width: 50%;

    .logo {
      font-size: 16pt;
      cursor: pointer;
      color: #507ebd;
    }
  }
}

.navBarDiv {
  .link {
    cursor: pointer;
    font-size: 16pt;
    color: #5677d2;
    margin-right: 15px;

    &:hover {
      color: rgba(86, 119, 210, 0.5)
    }
  }
}