.modalLoadProcess {
  background-color: rgba(0, 0, 0, .8) !important;
}

.modalLoadProcess .loadProcess {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  border-radius: 3px;
  width: 15%;
  height: 40px;
  margin: 0 auto;
}