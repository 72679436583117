@tailwind base;
@tailwind components;
@tailwind utilities;

@import "variables";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  //font-size: 12pt !important;
}

body {
  font-family: "Montserrat", sans-serif !important;
  line-height: 1.5 !important;
  background-color: $white !important;
}

a {
  text-decoration: none !important;
  color: #000;
}

strong {
  font-weight: bold;
}

textarea {
  width: 100%;
}

img {
  display: block;
  max-width: 100%;
}

*::-moz-selection {
  background: #469cff;
}

*::selection {
  background: #469cff;
}

option:hover {
  background: #469cff;
}

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: $blue;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #1356bb;
}

::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}

/* Fonts*/
@font-face {
  font-family: "Montserrat";
  src: url("../../src/assets/fonts/Montserrat-VariableFont_wght.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
/* Fonts-end */