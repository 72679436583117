@import 'variables';

.container {
  max-width: 100% !important;
}

.app-main-dark {
  background-color: $background_main_dark;
  height: $full_height;
}

.app-main {
  background-color: $white;
  height: $full_height;
}

.modal-header-dark {
  background-color: $background_second_dark;
  color: $blue;
  border-radius: 0 !important;
  border-style: none !important;
}

.modal-body-dark {
  background-color: #1c2128;
  color: $blue;
}

.modal-footer-dark {
  background-color: $background_second_dark !important;
  border-radius: 0 !important;
  border-style: none !important;
}

.table-dark {
  border-color: $border_color_blue !important;
  color: $white !important;
}

.table-dark tbody {
  border-color: $border_color_blue !important;
}

.table-dark tbody tr {
  min-height: 40px;
}

.table-dark tbody textarea {
  background-color: $background_input_text_dark;
  color: $white;
}

.table-dark tbody input[type='text'] {
  background-color: $background_input_text_dark;
  color: $white;
}

.copy-icon-dark {
  fill: $white !important;
}

.copy-icon-dark:hover {
  fill: $blue !important;
}

.audio-icons-dark {
  fill: $white !important;
}

.technicalMaintenance {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 50vh;
  border: 2px solid $border_color_blue;
  border-radius: 8px;
  margin: 10% auto 0 auto;
}

.technicalMaintenance button {
  margin-top: 20px;
}

.technicalMaintenance span {
  font-size: $default_text;
}

.technicalMaintenance strong {
  font-size: 16pt;
  margin-bottom: 5px;
}

.list-group-item:last-child {
  display: flex;
  justify-content: center;
}

.list_group_item_submenu {
  display: none;
}

.list_group_item_submenu .list-group-item {
  display: flex;
  justify-content: space-between;
}

.list-group-item {
  user-select: none;
  padding: 0.5rem 0.5rem !important;
  word-break: break-all;
}

.list-group-item strong {
  user-select: none;
  font-size: 15pt;
  word-wrap: break-word;
}

.list-group-item .icon-arrow {
  transform: rotate(180deg);
}

.list-group-item-action:hover {
  background-color: rgb(13 110 253 / 50%) !important;
}

.list-group-item:hover {
  background-color: $blue;
}

.list-group-item.active {
  background-color: $blue !important;
}

.submenu-list-group {
  justify-content: center;
  margin-bottom: 20px !important;
}

.skip-option textarea {
  @include option-text;
}

.add-option textarea {
  @include option-text;
}

.tab-pane-item-common {
  min-height: 86vh;
}

.tab-pane-item {
  min-height: 86vh;
  background-color: #F5F6F8;
  padding: 15px;
}

.tab-pane-item .list-group-horizontal {
  margin-top: 10px;
}

.tab-pane-item-dark {
  background-color: $background_main_dark;
}

#icons-block {
  display: flex;
  margin-bottom: 1rem;
  justify-content: flex-end;
  align-items: center;
}

#icons-block svg {
  fill: $black;
}

#icons-block svg:hover {
  cursor: pointer;
  fill: $blue;
}

.tab-pane-item_content_block .tab-pane-item_content_block__options {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.tab-pane-item_content_block .tab-pane-item_content_block__options .btn-sm {
  margin-right: 5px;
}

.tab-pane-item_content_block .tab-pane-item_content_block__options .btn-sm:last-child {
  margin-right: 0;
}

.tab-pane-item_content_block__content .form-control {
  resize: none;
}

.tab-pane-item_content_block__content .row {
  align-items: center;
}

.tab-pane-item_content_block__content .row .col-sm-1 {
  text-align: center;
}

.tab-pane-item_content_block__content .svg_icon {
  fill: $blue;
  position: absolute;
  margin-left: -30px;
  margin-top: -30px;
}

.tab-pane-item_content_block__content .svg_icon:hover {
  fill: $red;
  cursor: pointer;
}

.tab-pane-item_content_block__content img {
  cursor: pointer;
  max-width: 100%;
}

.tab-pane-item_content_block__content strong {
  font-size: $sub_title_text;
  text-align: center;
}

.image-fullscreen {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100vw;
  height: $full_height;
  background-color: #000000d9;
  cursor: pointer;
}

.image-fullscreen img {
  cursor: auto;
  max-width: 98%;
  max-height: 100%;
}

.image-fullscreen img:hover {
  transform: none;
  cursor: default;
}

.upload-icon {
  width: 24px;
  height: 24px;
}

.model-body__chooser_text {
  color: $blue;
  cursor: pointer;
}

.model-body__chooser_text:hover {
  color: $black;
}

.tab-content > .active {
  min-height: 80vh;
}

.audio-player__title {
  display: flex;
  justify-content: center;
  width: 100%;
}

.audio-player__title span {
  word-break: break-all;
}

#textarea-change-name {
  resize: none;
  overflow: hidden;
}

.reload-title {
  font-size: $title_text;
}

.reload-title-dark {
  font-size: $title_text;
  color: $white;
}

.reload-href {
  font-size: $sub_title_text;
  color: $blue;
  cursor: pointer;
  user-select: none;
}

.error-router {
  display: block;
  padding: 1rem;
}

.error-router h3 {
  font-size: 22pt;
}

.treatment-item-points {
  margin: auto;
  display: flex;
  justify-content: space-evenly;
}

.treatment-item-points .add-point {
  width: 45%;
  background-color: #58e058;
  border-radius: 8px;
  max-height: 25px;
}

.treatment-item-points .add-point input {
  max-width: 70px;
}

.treatment-item-points .skip-point {
  width: 45%;
  background-color: $red;
  border-radius: 8px;
  max-height: 25px;
}

.treatment-item-points .skip-point input {
  max-width: 70px;
}

.custom-floating-input {
  width: 40%;
  max-width: 310px;
  padding: 5px 10px;
  border: 2px solid $blue;
  border-radius: 16px;
}

.custom-floating-input-dark {
  background-color: $background_input_text_dark;
  color: $white;
  width: 40%;
  max-width: 310px;
  padding: 5px 10px;
  border: 2px solid $blue;
  border-radius: 16px;
}

.hr-blue {
  color: $blue;
  height: 4px !important;
  border-radius: 4px;
}

.edit-icon {
  fill: $blue;
}

.edit-icon-svg {
  cursor: pointer;

  path, rect {
    fill: #000;
  }

  &:hover {
    path, rect {
      fill: #da4545;
    }
  }
}

.edit-icon-svg-dark .edit-icon {
  fill: $white;
}

.edit-icon-svg-dark:hover .edit-icon {
  fill: #da4545;
}

.edit-icon-svg-dark {
  cursor: pointer;
}

.red-circle {
  display: inline-block;
  position: relative;
  margin-right: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 1px solid #8c1212;
  background-color: $red;
}

.form-select-dark {
  border-color: #0a53be !important;
  background-color: $background_second_dark !important;
  background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27><path fill=%27none%27 stroke=%27%230d6efd%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/></svg>") !important;
}

.form-select-dark-disable {
  border-color: #0a53be !important;
  background-color: $background_second_dark !important;
  background-image: none !important;
}

.close-button-dark {
  background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27red%27><path d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/></svg>") !important;
}

.thead-sticky {
  position: sticky;
  top: 0;
  background-color: $blue;
  border-left: 1px solid $blue;
  z-index: 1;
  color: $white;
  --bs-table-bg: 0;
}

.thead-sticky th {
  border-width: 0 0 !important;
  text-align: center;
}

.thead-sticky tr {
  border-width: 0 0 !important;
  text-align: center;
}

.edit-form-item {
  display: flex;
  width: 99%;
  margin-bottom: 7px;
  align-items: center;
}

.edit-form-item .edit-form-item-light {
  margin: 7px;
  background-color: #d9d9d9;
  width: 100%;
  border-radius: 0.3rem;
  padding: 0.5rem;
  color: $black;
}

.edit-form-item .edit-form-item-dark {
  margin: 7px;
  background-color: #47474b;
  width: 100%;
  border-radius: 0.3rem;
  padding: 0.5rem;
  color: $white;
}

.edit-form-item .edit-form-item-dark input[type='text'] {
  background-color: $background_input_text_dark;
  color: $white;
}

.form-control-dark input {
  background-color: $background_input_text_dark !important;
  color: $white !important;
}

.form-control-dark label {
  color: $white;
}

.form-control-dark-ni input {
  background-color: $background_input_text_dark;
  color: $white;
}

.form-control-dark-ni label {
  color: $white;
}

.refresh-icon {
  opacity: 1;

  &:hover {
    cursor: pointer;
    opacity: .7;
  }
}


/* Utils */
.mr-3 {
  margin-right: 1rem;
}

.ml-3 {
  margin-left: 1rem;
}

.text-align-center {
  text-align: center !important;
}

.input-dark {
  background-color: $background_input_text_dark !important;
  color: $white !important;
}

.textarea-dark {
  background-color: $background_input_text_dark !important;
  color: $white !important;
}

.color-dark {
  color: $white !important;
}

.dark {
  border-color: $border_color_blue !important;
  background-color: $background_second_dark !important;
  color: $white !important;
}

.min-vh-94 {
  min-height: 94vh !important;
}

.vh-90 {
  height: 90vh !important;
}

.max-w-85 {
  max-width: 85px;
}

.w-20 {
  width: 20%;
}

.w-60 {
  width: 60px;
}

.red {
  background-color: $red !important;
}

.very-red {
  background-color: $very_red !important;
}

.add-option {
  background-color: #58e058 !important;
  max-width: 60px !important;
}

.add-option input {
  width: 50px;
}

.skip-option {
  background-color: $red !important;
  max-width: 60px !important;
}

.skip-option input {
  width: 50px;
}

.index-table-row {
  width: 35px;
  text-align: center;
}

.active-user {
  background-color: #9f9e9e;
}

.label-style {
  margin-top: 10px;
}

.label-style .select-style {
  display: flex;
  margin-bottom: 10px;
}

.label-style .select-style span {
  margin-left: 10px;
}

.label-style .icon-style {
  cursor: pointer;
  margin-left: 6px;
  font-size: 23px;
}

.generate-icon .generate-icon__rect {
  opacity: 0;
}

.generate-icon .generate-icon__path1 {
  fill: #2a43e3;
}

.generate-icon .generate-icon__path2 {
  fill: $red;
}

.generate-icon .generate-icon__path3 {
  fill: $red;
}

.generate-icon:hover .generate-icon__path3 {
  fill: $blue;
}

.generate-icon:hover .generate-icon__path2 {
  fill: $blue;
}

/* Styles for carousel */
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%230d6efd%27><path d=%27M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z%27/></svg>") !important;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%230d6efd%27><path d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z%27/></svg>") !important;
}

.carousel-dark .carousel-control-next-icon, .carousel-dark .carousel-control-prev-icon {
  filter: none !important;
}

.carousel-control-prev {
  left: $control-left-right !important;
  width: 2rem !important;
}

.carousel-control-next {
  right: $control-left-right !important;
  width: 2rem !important;
}

.modal-search {
  width: 50%;
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
}

.modal-search .custom-input {
  width: 100%;
  padding: 5px 10px;
  border: 2px solid $blue;
  border-radius: 16px;
}

.text-color-dark {
  color: $white;
}

.test-header-wallpaper {
  background: linear-gradient(to bottom, #e52d27, #b31217);
}

.app-body {
  width: 100vw;
  height: 93vh;
  max-width: 100%;
  background-color: white;
  overflow-y: auto;
}

.main-block {
  margin-top: 20px;
  display: flex;
  flex-flow: row-reverse;
  width: 1500px;
}

@keyframes appearance-last {
  0% {
    margin-right: 100%;
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

.navigator-date {
  width: 100%;
  height: 45px;
  background: rgb(199, 199, 199);
  border-style: none;
  border-radius: 5px;
  user-select: none;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;

  button {
    margin: 0 auto;

    &:focus {
      outline: 0;
      box-shadow: 0 0 0 0 rgb(0 0 0 / 0);
      opacity: 1;
    }
  }
}

.show-hide-table {
  float: right;
  margin-right: 25px;
  margin-top: 5px;
  width: 26px;
  height: 26px;

  .hide-table-polygon {
    stroke: white;
    stroke-width: 5px;
  }

  &:hover {
    cursor: pointer;

    .hide-table-polygon {
      stroke: #0d6efd;
    }
  }
}

.result-paragraph {
  font-size: 18pt;
  position: sticky;
  top: 0;
  font-family: "Mulish Bold", sans-serif;
  background: linear-gradient(to left, #789fd2, #006eff);
  color: white;
  z-index: 3;
  padding: 5px 15px;
  //border-top-right-radius: 25px;
}

.correct-answer {
  background-color: #76b689;
}

.incorrect-answer {
  background-color: #e86767;
}

.warn-answer {
  background-color: #fcbf00;
}

.result-field {
  background-color: #0d6efd;
}

.incorrect-answer:hover {
  background-color: #d2c4c4;
}

.warn-answer:hover {
  background-color: #d2c4c4;
}

.correct-answer:hover {
  background-color: #d2c4c4;
}

.result-field:hover {
  background-color: #d2c4c4;
}


.btn-group-vertical {
  position: fixed !important;
  left: -2px;
  top: 30%;
  width: 10%;
  height: 40%;

  button {
    text-align: end;
    border-top-right-radius: 20px !important;
    border-left: none !important;
  }

}

.history-list {
  font-family: "Mulish Regular", sans-serif;
}

.slow-open {
  animation-name: open;
  animation-duration: 800ms;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  animation-delay: 0s;
}

.slow-close {
  animation-name: close;
  animation-duration: 800ms;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-delay: 0s;
}

.react-datepicker-wrapper {
  width: 90% !important;

  .react-datepicker__input-container {
    input {
      width: 100%;
      height: 45px;
      background: rgba(0, 0, 0, 0);
      border-style: none;
      outline: none;
      padding: 5px 12px;
    }
  }
}


.react-datepicker-popper {
  z-index: 10 !important;
}

@keyframes open {
  0% {
    display: block;
    opacity: 0;
    visibility: hidden;
  }
  100% {
    display: block;
    opacity: 1;
    visibility: visible;
  }
}

@keyframes close {
  0% {
    display: block;
    opacity: 1;
    visibility: visible;
  }
  100% {
    display: none;
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes gradient {
  0% {
    background-position: 20% 0;
  }
  50% {
    background-position: 80% 100%;
  }
  100% {
    background-position: 20% 0;
  }
}

.form-stat {
  padding: 0 10px;
  width: 20%;
  max-height: 93vh;
  text-align: center;
  background-color: #f6f6f6;
  overflow-y: auto;
  border-radius: 5px;

  &::-webkit-scrollbar {
    width: 0;
  }

  table {
    * {
      text-align: center;
    }

    tbody tr {
      cursor: pointer;
    }
  }

  .chart-block {
    margin-top: 20px;

    button {
      float: right;
    }

    canvas {
      margin-bottom: 30px;
    }
  }
}

.history {
  overflow-y: auto;
  width: 100%;

  thead {
    width: 100%;
    background: linear-gradient(to right, #507ebd, #789fd2) border-box;
    position: sticky;
    top: 0;

    tr {
      font-size: 14pt;
      text-align: center;
      color: white;

      :first-child {
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
      }

      :last-child {
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #0847a6;

      td {
        text-align: center;

        span:hover {
          cursor: pointer;
          color: #0d6efd;
          fill: #0d6efd;
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.min-vw-8 {
  min-width: 8vw;
}

/*Dark theme utils*/
.dark-bg {
  background: #2d333b !important;
}

.dark-body-bg {
  background: #22272e !important;
}

.dark-el {
  background-color: #2d333b !important;
  color: #f5eded !important;
}

.dark-text {
  color: #f5eded !important;
  fill: #f5eded;
}

.dark-form-select {
  border-color: #0a53be !important;
  color: #0d6efd !important;
  background-color: #2d333b !important;
  background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27><path fill=%27none%27 stroke=%27%230d6efd%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/></svg>") !important;
}

.autocomplete-dark {
  label {
    color: white;
  }

  input {
    color: white !important;
  }

  .css-ptiqhd-MuiSvgIcon-root {
    fill: white;
  }

  fieldset {
    border-color: #0d6efd;
  }

  &:hover {
    fieldset {
      border-color: #0049b0 !important;
    }

  }

}

.form-control-dark {
  label {
    color: white;
  }

  input {
    background-color: #2c3034 !important;
    color: white !important;
  }
}

.modal-header-dark {
  background-color: #2d333b;
  color: #0c63e4;
  border-radius: 0 !important;
  border-style: none !important;
}

.modal-body-dark {
  background-color: #1c2128;
  color: #0d6efd;
}

.modal-footer-dark {
  background-color: #2d333b !important;
  border-radius: 0 !important;
  border-style: none !important;
}

.table-dark {
  border-color: #0c63e4 !important;
  color: #fff !important;
  --bs-table-bg: transparent !important;

  tbody {
    border-color: #0c63e4 !important;

    tr {
      min-height: 40px;
    }

    textarea {
      background-color: #2c3034;
      color: white;
    }

    input[type='text'] {
      background-color: #2c3034;
      color: white;
    }
  }
}

/*Dark theme utils - End*/

@media (min-width: 700px) and (max-width: 1400px) {
  .treatment-item-points {
    flex-direction: column;
    align-items: center;

    .add-point {
      margin-bottom: .5rem;
    }
  }
}

@media (max-width: 1400px) {
  .container {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .carousel-control-prev {
    left: 0 !important;
    width: 2rem !important;
    background: rgba(0, 0, 0, 0.55) !important;
  }

  .carousel-control-next {
    right: 0 !important;
    width: 2rem !important;
    background: rgba(0, 0, 0, 0.55) !important;
  }
  .thead-sticky {
    & * {
      white-space: nowrap;
    }
  }
}

@media (max-width: 700px) {
  .treatment-item-points {
    margin-top: 1rem !important;
  }
}