@import "src/styles/variables";

.contextMenu {
  position: absolute;
  width: 250px;
  z-index: 1001;
  display: flex;
  flex-flow: column nowrap;
  background-color: rgba(255, 255, 255, 0.94);
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.29);
  border-radius: 10px;
  cursor: pointer;
  user-select: none;
  padding: 5px !important;

  & > div {
    position: relative;
    width: 100%;
    height: 40px;
    display: flex;
    flex-flow: row;
    align-items: center;
    border-radius: 8px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.47);
    }

    span {
      width: 90%;
      text-align: center;
    }

    .editIconSvg g path, .editIconSvg g rect{
      fill: #000000;
    }

    .copyIconSvg {
      fill: #000000;
    }

    .addIconSvg {
      fill: $blue;
    }

    .recoveryIconSvg {
      height: 20px;
    }
  }
}