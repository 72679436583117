$blue: #0d6efd;
$blue_dark: #003789;
$red: #e83030;
$grey: #838383;
$very_red: rgb(219, 0, 0);
$background_main_dark: #22272e;
$background_second_dark: #2d333b;
$background_input_text_dark: #2c3034;
$background_textarea_dark: #4c4c4c;
$border_color_blue: #0c63e4;
$full_height: 100vh;
$control-left-right: 18%;
$white: #F5F6F8;
$black: #000000;
$title_text: 20pt;
$small_title_text: 16pt;
$sub_title_text: 14pt;
$default_text: 12pt;

@mixin option-text {
  resize: none;
  height: 35px;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}